*{
  font-family: "Montserrat" !important;
}
.multiStepForm {
    background-color: var(--neutral-100);
    width: min(100%, 1000px);
    padding: 1rem;
    border-radius: 0.5rem;
    display: grid;
    grid-template-columns: 320px 1fr;
    height: min(85%, 900px);
    box-shadow: 10px 10px 80px -10px blue (0, 0, 0, 0.103);
    overflow: hidden;
    flex-wrap: wrap;
    margin-right: 3rem;
  }
  .steps {
    padding: 1rem 3rem; 
    border-radius: inherit;
    background-color: #f3f5fc;
    display: flex;
    align-content: space-around;
    flex-direction: column;
    gap: 2rem; 
    overflow: auto;
    margin-top: -1rem; 
    margin-bottom: -1rem; 
    margin-left: -1rem;
    margin-right: 3rem; 
    border-radius: 0.5rem 0 0 0.5rem;
    font-family: "Montserrat" !important;   
  }
  .MuiStepper-root{
    flex-direction: column;
    flex-wrap: wrap;
    align-content: space-around;
  }
  .MuiStepper-root .MuiStepConnector-vertical {
    margin-left: 0.5rem; 
    padding: 0;
  }
  .MuiStepper-root .MuiStep-root {
    padding: 0;
    gap: 0;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: space-around;
  }
  .MuiStepper-root .MuiStepConnector-vertical {
    margin-left: 12px;
    margin-bottom: -8px; 
    margin-top: -8px;
  }
  .MuiStepper-root .MuiStepConnector-horizontal{
    width: 100%;
  }
  .MuiStepper-root .MuiStepIcon-root {
    width: 25px;
    height: 25px;
  }
  .MuiStepper-root .MuiStepIcon-active {
    color: var(--primary-400);
  }
  .MuiStepLabel-label p {
    font-weight: 500;
    color: black;
    font-family: "Montserrat" !important;  
  }
  .MuiStepLabel-root{
    padding: 8px 0px !important;
  }
  .css-8t49rw-MuiStepConnector-line {
    display: block;
    border-color: #bdbdbd;
    border-left-style: solid;
    border-left-width: 1px;
    min-height: 26px !important;
}
.stepper-logo{
    width: 90%;
    height: auto;
    margin-top: -30px;
}
.stepper-label{
  font-weight: 500 !important;
    color: #0d4d72 !important;
    font-size: 14px !important;
    font-family: "Montserrat" !important;  
}
@media (max-width: 900px){
  .stepper-label{
    display: none;
  }
  .active-stepper-label{
    display: none;
  }
  .stepper-container-logo{
    width: fit-content !important;
    height: 105px !important;
    object-fit: cover;
  }
  .stepper-logo{
    margin-left: 20px;
    margin-top: 10px;
    width: 70%;
  }
  .stepper-label-grid{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
  }
}
@media (max-width:426px){
  .stepper-logo{
    width:100%;
  }
}
.active-stepper-label{
  font-weight: 700 !important;
  color: #1976d2 !important;
  font-size: 14px !important;
  font-family: "Montserrat" !important;  
}
.stepper-div{
  margin-top: -18%;
  margin-bottom: 30px;
}
@media (max-width:900px){
  .stepper-div{
    margin-top: 35px;
    margin-bottom: 0px;
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: nowrap;
    width: 90%;
  }
}
.stepper-container-logo{
  width: 232px;
  height: 212px;
  display: flex;
    align-items: center;
    justify-content: center;
}
.stepper-main-grid{
  display: flex;
    flex-direction: row;
    align-items: center;
}
@media (max-width:900px){
  .css-a5nipc {
    flex-shrink: 0;
    display: flex;
    padding-right: 0px !important;
  }
}
@media (max-width:900px){
  .css-z7uhs0-MuiStepConnector-line {
    display: block;
    border-color: #bdbdbd;
    border-top-style: solid;
    border-top-width: 1px !important;
 }
}
@media (max-width:900px){
  .css-vnkopk-MuiStepLabel-iconContainer{
    padding-right: 0px !important;
  }
}