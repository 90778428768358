*{
    font-family: "Montserrat" !important;
}

body {
    font-family: "Montserrat" !important;  
    margin: 0;
    padding: 0;
    height: 100%;
}
.content-container {
    border: 2px solid black;
    padding: 20px;
    width: 80%;
    margin: 0 auto;
    text-align: left;
    overflow-y: auto !important;
    max-height: calc(100vh - 110px); 
    margin-top: 10px;
    margin-bottom: 20px;
}
.content-container h1{
    text-align:center;
    color:#0d4d72;
}
.content-container h2 {
    color: blue;
    text-align: left;
}
.content-container p {
    font-size: 18px;
    text-align: left;
    font-family: "Montserrat" !important;  
}
.content-container img {
    max-width: 100%;
    height: auto;
    font-family: "Montserrat" !important;  
}
footer {
    text-align: center;
    font-size: 14px;
    color: #666;
    padding: 1px;
    left:0px;
    right:0px;
    background-color: #f0f0f0;
    position: fixed;
    bottom: 0;
    width: 100% ;
}
.footer-style p{
    margin-right: -100px;
}
::-webkit-scrollbar {
    width: 5px;
}
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
}
::-webkit-scrollbar-thumb {
    background: hsl(201.98deg 79.53% 24.9%); 
    border-radius: 10px;
}
  ::-webkit-scrollbar-thumb:hover {
    background: hsl(207.69deg 97.85% 18.24%); 
}