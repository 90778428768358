*{
    font-family: "Montserrat" !important;
}
.main-container{
    display: flex;
    flex-direction: column !important;
    align-items: center;
    justify-content: center;
    padding-right: 8px;
    padding-left: 8px !important;
}
@media (max-width:900px){
    .main-container{
    padding-right: 0px;
    }
}
.skip-button{
    display: flex;
    align-items: center;
}
.loading-container{
    display: flex;
    justify-content: center;
    align-items: center;
    background: #d3e3fd47;
    border-radius: 0px 25px 25px 0px;
    width: 90%;
}
.action-buttons{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
    gap: 68%;
    padding: 2%;
}
.three-action-buttons{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
    gap: 30%;
    padding: 2%;
}
@media (max-width:900px){
    .action-buttons{
        gap:55%;
    }
    .three-action-buttons{
        gap: 17%;
    }
}
.main-card{
    display: flex;
    justify-content: center;
}
@media (max-width:900px){
    .main-card{
        height: 100vh;
    }
}
.stepper-container{
    display: flex;
    justify-content: center;
    background: #eceff7;
    border-radius: 25px 0px 0px 25px;
    padding-top: 0px !important;
    padding-left: 0px !important;
}
@media (max-width:900px){
    .stepper-container{
        height: 15%;
        align-items: center;
        border-radius: 0px;
    }
}
.connect-container{
    box-shadow: 18px 13px 20px 3px #02295a1f;
    border-radius: 25px;
    display: flex;
    background-color: white;
}
@media (max-width:900px){
    .connect-container{
        height: 100%;
        border-radius: 0px;
        align-content: flex-start;
    }
}
.magnifyingGlassPopUp{
    display: flex;
    flex-direction: column !important;
    align-items: center;
    justify-content: center;
}
.magnifying-glass-icon{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 150px;
}
.popup-note-text{
    font-size: 15px !important;
}
.children-container{
    width: 90%;
}
@media (max-width:900px){
    .children-mob-container{
        height: 63vh;
        overflow-y: scroll;
        overflow-x: hidden;
    }
}