*{
    font-family: "Montserrat" !important;
}
.landing-page-main-container{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    font-family: "Montserrat" !important;
}
.home-container{
    box-shadow: 18px 13px 20px 3px #02295a1f;
    border-radius: 25px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px !important;
    background-color: #fdfdfd;
    font-family: "Montserrat" !important;  
}
.landing-image{
    width: 100%;
    height: auto;
    object-fit: cover;
}
@media (max-width: 484px){
    .landing-image{
        width: 90%;
        height: auto;
        object-fit: cover;
    }
}
@media (max-width:1025px){
    .landing-image-container{
        display: flex;
    align-items: center;
    justify-content: center;
    }
    .landing-image{
        width: 100%;
        height: auto;
        object-fit: cover;
    }
}
.landing-logo{
    width: 70%;
    height: auto;
    margin-top: 20px;
}
.title{
    font-size: 20px;
    text-align: center;
    font-family: "Montserrat" !important;

}
.sub-title{
margin-top: 8px !important;
margin-bottom: 8px !important;
font-size: 12px !important;
font-family: "Montserrat" !important;  
}
@media (max-width:900px){
    .sub-title{
        text-align: center;
    }
}
.right-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: "Montserrat" !important;
}
@media (max-width:426px){
    .right-content{
        margin-top: -40px;
        margin-bottom: 56px;
    padding: 4px;
    font-family: "Montserrat" !important;
    }
}
.landing-image-container{
    background-color: #2f70e0;
    border-radius: 25px 0px 0px 25px;
    width: 484px;
    height: 484px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Montserrat" !important;
}
@media (max-width: 900px){
    .landing-image-container{
        width: 100%;
        height: auto;
        object-fit: cover;
        border-radius: 0px;
    }
}
.right-sub-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 25px;
    gap: 35px;
    align-items: flex-end;
    font-family: "Montserrat" !important;
}
@media (max-width:900px){
    .right-sub-container{
        gap:10px;
        align-items: center;
    }
}
.right-content-div{
    padding-right: 30px;
    display: flex;
    flex-direction: column !important;
    padding-left: 30px;
    font-family: "Montserrat" !important;
}
@media (max-width: 484px){
    .right-content-div{
        padding-right: 0px;
        padding-left: 10px;
        margin-top: 0px !important;
        font-family: "Montserrat" !important;
    }
}
.home-logo-container{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 415px;
    height: 295px;
    margin-bottom: -70px;
    margin-top: -21%;
    font-family: "Montserrat" !important;
}
@media (max-width: 415px){
    .home-logo-container{
        width: 100%;
        height: auto;
        font-family: "Montserrat" !important;
    }
}
.right-end-container{
    margin-right: -30px;
    margin-top: -37px;
    font-family: "Montserrat" !important;
}
@media (max-width: 426px){
    .right-end-container{
        margin-right: 0px;
        font-family: "Montserrat" !important;
    }
}
.partner-logo-container{
    width: auto;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    font-family: "Montserrat" !important;
}
.partner-image{
    width: auto;
    height: auto;
    padding: 10px;
    font-family: "Montserrat" !important;
}
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .popup-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    width: 400px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .close-button {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }
  
  .popup-body {
    margin-top: 10px;
  }
  