*{
    font-family: "Montserrat" !important;
}
.success-page-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 15px;
}
.gif-container{
    display: flex;
    flex-direction: column !important;
    margin-top: -140px !important;
    z-index: 0 ;
    justify-content: center;
    align-items: center;
    height: 330px !important;
}
.user-info-container{
    display: flex;
    flex-direction: row !important;
    align-items: center;
    padding-top: 1px !important;
    max-width: 542px !important;
    z-index: 1 !important;
}
.success-title-container{
    z-index: 1 !important;
    height: 90px !important;
}
.success-title{
    text-align: center;
    font-size: 2.2rem;
}
@media (max-width:426px){
    .success-title{
        font-size: 14px;
    }
}
.user-info-value{
    font-weight: 700;
    font-size: 14px;
    line-height: normal;
}
.user-info-label{
    text-align: center;
    width: max-content;
    display: flex;
    flex-direction: row;
    margin-left: 10px;
}
@media (max-width:426px){
    .user-info-label{
        margin-left: -4px;
        font-size: 9px !important;
    }
    .user-info-value{
        font-size: 9px !important;
    }
    .success-page-container {
        padding: 0px;
        padding-left: 10px;
        overflow-x: hidden;
    }
}
.user-label-container{
    text-align: left;
    width: max-content;
    border-right: 1px solid #d6d9e6;
    padding-left: 13px !important;
    padding-top: 3px !important;
    display: flex;
    align-items: center;
}
@media (max-width:900px){
    .user-label-container{
        padding-left: 11px !important;
        padding-top: 8px !important;
    }
}
.user-value-container{
    background: #eceff7;
    text-align: center;
}
.copyIcon{
    text-align: center;
    border-left: 1px solid #d6d9e6;
    display: flex;
    align-items: center;
    padding-top: 0px !important;
    justify-content: center;
}
.success-page-text{
    font-size: 15px;
    margin-top: 142px;
    position: fixed !important;
}


.user-info-div{
    margin-top: 0px !important;
    border: 1px solid #d6d9e6;
    border-radius: 9px;

}
.phone-number{
    display: flex;
    flex-direction: row;
    align-items: stretch;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 0px !important;
    margin-top: 0px !important;
    align-items: center;
    cursor: pointer;
    margin-top: 258px !important;
    position: fixed !important;
}
.Success-Msg{
    margin-top: -17px;
}
.copy-text{
    margin-left: 3px;
}
.phonenumber-text{
    margin-top: -83px;
    font-weight: 500 !important;
    font-size: 13px!important;
    line-height: normal;

    }
.copy-icon-small{
    font-size: small !important;
    padding: 5px;
}
.user-message-display{
    display: flex !important;
    justify-content: space-around !important;
}
.Success-Message{
    margin-top: -15px
}