*{
  font-family: "Montserrat" !important;
}
.btn-class {
    display: flex; 
     justify-content: center; 
     gap: 10%;
     line-height: 7rem;
   }
   .btn-class button {
    background-color: #1976d2;
  }
  .btn-class button:hover {
    background-color: #1976d2d1; 
  }
   .guidePopUp{
    display: flex;
    flex-direction: column !important;
    align-items: center;
    justify-content: center;
   }
   .attentionIcon{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 150px;
   }
   .note-text{
    font-size: 15px !important;
    text-align: center;
   }
   .paragraph{
    line-height: 150%;
    margin-top: 0%;
    font-size: 11px;
   }
   .crm-tools-grid{
    padding-left: 25px !important;
   }
   @media (max-width:900px){
    .crm-tools-grid{
      padding-left: 0px !important;
    }
   }
   div[class^="acWidgetContainer-"] {
           position: fixed !important;
           top: 35% !important;
           right: -8% !important;
           transform: translate(-50%, -50%) !important;
           z-index: 999999999 !important;
         }
         button[class^="acCloseButton-"] {
           position: absolute;
           bottom: -150px; 
           right: 0;
           transform: translateX(-50%, -50%);
           height: 45px;
           border-radius: 50%;
           width: 50px;
           color: #ffffff;
           background: #0d4d72 !important;
           cursor: pointer;
           z-index: 999999999 !important;
         }
.head-container{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
}
.crm-container{
  display: flex !important;
    justify-content: center !important;
    align-items: center;
    height: 400px;
}
.crm-button-div{
  width: 100%;
}
@media (max-width:900px){
  .crm-container{
    margin-top: 0px !important;
    text-align: center;
    padding-left: 8px;
  }
}
@media (max-width:900px){
  .crm-button-div{
  width: 90%;
  }
}
.guide-button button{
  background-color: #1976d2;
  padding: 10px 31px;
}