*{
    font-family: "Montserrat" !important;
}
body {
  /* font-family: "Montserrat", sans-serif !important; */
  font-family: "Montserrat" !important;

  --primary-800: hsl(213, 96%, 18%);
  --primary-400: hsl(243, 100%, 62%);
  --primary-300: hsl(228, 100%, 84%);
  --primary-200: hsl(206, 94%, 87%);
  --secondary-400: hsl(354, 84%, 57%);
  --neutral-400: hsl(231, 11%, 63%);
  --neutral-300: hsl(229, 24%, 87%);
  --neutral-100: hsl(231, 100%, 99%);
  --white: hsl(0, 0%, 100%);
  background-color: #19191a1f;
  overflow: hidden;
  user-select: none;
}
:root {
    /* font-family: "Montserrat", sans-serif !important; */
    font-family: "Montserrat" !important;
    --primary-800: hsl(213, 96%, 18%);
    --primary-400: hsl(243, 100%, 62%);
    --primary-300: hsl(228, 100%, 84%);
    --primary-200: hsl(206, 94%, 87%);
    --secondary-400: hsl(354, 84%, 57%);
    --neutral-400: hsl(231, 11%, 63%);
    --neutral-300: hsl(229, 24%, 87%);
    --neutral-100: hsl(231, 100%, 99%);
    --white: hsl(0, 0%, 100%);
    
}
code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', */
    /* monospace; */
    font-family: "Montserrat" !important;

}
h1,
h2,
h3,
h4
 {
    color: var(--primary-800);
    margin-bottom: 0.25em;
    font-family: "Montserrat" !important;  
}
h5 {
    color: var(--primary-800);
    font-weight: 500;
    font-size: 13px;
    font-family: "Montserrat" !important;  
}
h2 {
    font-size: 2rem;
    font-family: "Montserrat" !important;  
}
h3 {
    font-size: 1.2rem;
    font-family: "Montserrat" !important;  
}
h4 {
    font-size: 1.1rem;
    font-family: "Montserrat" !important;  
}
p {
    font-weight: 500;
    font-size: 13px;
    color: var(--primary-800);
    line-height: 0.25em;
    font-family: "Montserrat" !important;  
}
button{
    color: var(--primary-800);
}
@media (max-width: 900px){
    body{
        overflow-y: auto;
        overflow-x: none;
    }
}
::-webkit-scrollbar {
    width: 5px;
}
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
}
::-webkit-scrollbar-thumb {
    background: hsl(201.98deg 79.53% 24.9%); 
    border-radius: 10px;
}
  ::-webkit-scrollbar-thumb:hover {
    background: hsl(207.69deg 97.85% 18.24%); 
}  
