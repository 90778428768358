*{
    font-family: "Montserrat" !important;
}
.not-found-page-container{
    display: flex !important;
    flex-direction: column !important;
    align-content: space-between;
    justify-content: center;
    align-items: center;
    height: 65vh;
    width: 100%;
}
.not-found-text{
    text-decoration: underline;
}
.not-found-page-gif{
    width: initial;
    height: 220px;
}
.not-found-text-area{
    display: flex;
    flex-direction: column !important;
    justify-content: space-between;
    align-items: center;
}
.not-found-404-text{
    font-size: 150px !important;
    font-weight: 800 !important;
    color: #b6b4f8;
}
.not-found-sorry-text{
    font-weight: 600 !important;
    padding-bottom: 20px;
}
.not-found-oops-text{
    color: #f4521496;
    font-size: 24px !important;
}