*{
  font-family: "Montserrat" !important;
}
.input-class{
  align-items: center;
  margin-bottom: 4px !important;
}
.link-hover{
  cursor: pointer;
  color: blue;
  text-decoration: underline;
  margin-top: 10px;
  margin-bottom: 22px;
}
.add-more-queue-div{
  padding-top: 4px !important;
  margin-bottom: 8px !important;
}
.add-intents-div{
  margin-top: 20px !important;
}
.link-hovering{
  cursor:pointer;
  color: blue;
  text-decoration: underline;
  margin-top: -8px;
}
.additional-queue-container{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  flex-direction: row;
}
.additonal-queue-input{
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid #d6d9e6;
  border-radius: 7px;
  justify-content: center;
  align-items: center;
}
.container-section {
  display: flex;
  flex-direction: row;
}
.intent-item{
  display: inline-flex;
  align-items: center;
}
.intent-name {
  box-Sizing: "content-box";
    background-color: rgb(209, 224, 255);
    color: #333;
    padding: 3px 8px;
    border-radius: 5px;
    margin-right: 5px;
    /* margin-top: 3px; */
    display: flex;
    align-items: center;
    font-size: small;
}
.cancel-icon{
  bottom:5px;
  margin-left:5px ;
  margin-top: 1px;
  color: rgb(13, 76, 205);
  cursor: pointer;
}
.queue-container{
  display: flex;
  justify-content: center;
  align-items: center;
}
.queue-button{
  text-align: center;
}
.call-handling-section{
  height: 366px;
  overflow-y: overlay;
}
.custom-hour-days-container{
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: center;
}
.custom-hour-container{
  display: flex;
  flex-direction: column !important;
  align-items: center;
  justify-content: center;
  align-content: space-around; 
}
.custom-hour-form-container{
  height: 260px;
  overflow-y: auto;
  padding: 20px;
}
.custom-hour-button-container{
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.time-field-container{
  min-width: 100px !important;
  margin-bottom: 3px !important;

}
.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input{
  height: 7px !important;
}
.css-1st8yxe-MuiPickersLayout-root .MuiPickersLayout-actionBar {
   display : none; 
}
.css-jaa451 .MuiPickersLayout-actionBar{
  display: none !important;
}
.queue-form-container{
  height: 200px;
  overflow-y: auto;
}