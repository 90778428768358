*{
    font-family: "Montserrat" !important;
}
.loading-text{
    text-align: center;
    color: #9000e0;
}
@media (max-width:900px){
    .claim-phone-container-section{
        display: flex;
        justify-content: center;
        align-items: center;
    }
}