*{
  font-family: "Montserrat" !important;
}
.panel-title {
  position: relative;
  font-family: "Montserrat" !important;  
}
.collapsible-link {
  position: absolute;
  top: 100%;
  right: 0px;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
}
.collapsible-link::before,
.collapsible-link::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #333;
  top: 0%;
  left: 0;
  transition: all 0.3s;
}
.collapsible-link::after {
  transform: rotate(90deg);
}
.collapsible-link.open::before {
  transform: rotate(180deg);
}
.collapsible-link.open::after {
  transform: rotate(0);
}
.faq-page {
  background-color: #f9f9f9;
  border-radius: 10px;
}
.faq-content{
  overflow-y: auto !important;
  height:360px;
  padding: 20px;
}
@media (max-width:900px){
  .faq-page{
    width: 85%;
  }
}
.faq-page-card {
  border-radius: 10px;
  border: 1px solid #e0e0e0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
  position: relative;
  overflow: hidden;
}
.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 18px;
  color: #555; 
  cursor: pointer;
  border: none; 
  background: none; 
  padding: 0; 
  outline: none; 
}
.close-btn:hover {
  color: #000;
}
.card-body {
  padding: 20px;
}
.panel {
  margin-bottom: 20px;
}
.panel-heading {
  background-color: #f0f0f0;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
  font-weight: 500px;
  font-size: 13px;
  font-family: "Montserrat" !important;  
}
.panel-title {
  margin: 0;
  position: relative;
  display: flex;
  margin-right: 0px;
  justify-content: space-between; 
  align-items: center; 
  font-family: "Montserrat" !important;  
}
.panel-title a {
  color: black; 
  text-decoration: none; 
  font-size: 15px !important;
  font-family: "Montserrat" !important;  
}
.panel-collapse {
  transition: max-height 0.3s ease;
  overflow: hidden;
  max-height: 0;
}
.panel-collapse.in {
  max-height: 1000px; 
}
.question {
  font-weight: 500;
  font-size: 13px;
  color: black;
  text-align: left;
  max-width: 100%;
  margin-bottom: 10px;
  font-family: "Montserrat" !important;  
}
.answer {
  color: var(--primary--100);
  text-align: left;
  font-size: 13px;
  font-weight: 500;
  white-space:nowrap;
  max-width: 100%;
  word-wrap: break-word;
  margin-bottom: 20px; 
  white-space: normal; 
  word-wrap: break-word; 
  max-width: 100%;
  line-height: normal;
  padding:10px;
  font-family: "Montserrat" !important;  
}