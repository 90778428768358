*{
  font-family: "Montserrat" !important;
}
.container-admin{
  display: flex;
  align-items: center;
  justify-content: flex-start !important;
  margin-top: 10px !important;
}
.input-div-class{
  padding-top: 0px !important;
  margin-bottom: 0px !important;
 
}
.disablealter-text{
  color:var(--primary-800);
  text-decoration: underline;
 }
.error-message{
  font-size: 10px !important;
  color: #dc362e;
  font-family: "Montserrat" !important;
 
}
.input-fields{
  width: 150% !important;
 
}
.alternate-input-class{
  font-weight: normal;
  border-color: var(--neutral-300);
  border-radius: 10px;
  border-width: 1.5px;
  z-index: 1;
  padding: 0.55rem;
  border: 1px solid var(--neutral-300);
  border-radius: 0.5rem;
  background-color: var(--neutral-100);
  outline: none;
  font-weight: 500;
  width: 90% !important;
  height: 10px;
 
 
  /* font-family: "Montserrat", sans-serif !important; */
  font-family: "Montserrat" !important;
 
}
.email-label {
  padding: 10px;
  border-radius: 0.5rem;
  border: 2px solid hsl(213.41deg 95.65% 18.04%);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  font-weight: 700;
  box-sizing: border-box;
  width: -webkit-fill-available;
  font-family: "Montserrat" !important;  
}
 
.email-label > .MuiTypography-root {
  margin-right: 4px;
  overflow-y: auto !important;  
}
.button-styles{
  padding-top:300px;
}
 
.link-hover {
  cursor: pointer;
  color: blue; padding-right: 100px;
  padding-top: -80px;
  font-size: 13px;
}
.adminstarator-details-section{
  height: 366px;
  overflow-y: auto ;
  }
 
/* .cancel-style{
  bottom:5px;
 
 
  font-size:"small";
 
  cursor: pointer;
}
@media(max-width :462px){
  .cancel-style{
    bottom:5px;
    padding-left:192px  !important ;
    margin-top: -50px;
    font-size:"small";
    color: rgb(13, 76, 205);
    cursor: pointer;
  }
}
@media(max-width :900px){
  .cancel-style{
    bottom:5px;
    padding-left:200px  !important ;
    margin-top: -50px;
    font-size:"small";
    color: rgb(13, 76, 205);
    cursor: pointer;
  }
}
.add-button{
  padding-left: 810px;
  margin-top: -87px!important;
 
 
}
 
 
.adminstarator-details-section{
height: 366px;
overflow-y: auto ;
}
.alternate-input-class:hover, .text-input:focus {
  border-color: var(--primary-400);
  outline: none;
}
.textarea-input:hover, .textarea-input:focus {
  border-color: var(--primary-400);
  outline: none;
}
@media screen and (max-width: 1200px) {
  .content-container {
      width: 100%;
  }
}
 
@media screen and (max-width: 992px) {
  .cancel-style{
    bottom:5px;
    padding-left:170px  !important ;
    margin-top: -50px;
    font-size:"small";
    color: rgb(13, 76, 205);
    cursor: pointer;
  }
  .link-hover {
    cursor: pointer;
    color: blue; padding-right: 0px;
    padding-top: -80px;
    font-size: 13px;
  }
 
 
}
 
@media screen and (max-width: 768px) {
  .cancel-style{
    bottom:35px;
    padding-left:270px  !important ;
    margin-top: -50px;
    font-size:"small";
    color: rgb(13, 76, 205);
    cursor: pointer;
  }
  .link-hover {
    cursor: pointer;
    color: blue;
     padding-right: -30px;
    padding-top: -80px;
    font-size: 13px;
  }
 
 
}
 
@media screen and (max-width: 576px) {
  .cancel-style{
    bottom:5px;
    padding-left:100px  !important ;
    margin-top: -50px;
    font-size:"small";
    color: rgb(13, 76, 205);
    cursor: pointer;
  }
  .link-hover {
    cursor: pointer;
    color: blue; padding-right: 0px;
    padding-top: -80px;
    font-size: 13px;
  }
 
}
 
@media screen and (max-width: 414px) {
 
  .cancel-style{
    bottom:5px;
    padding-left:172px  !important ;
    margin-top: -50px;
    font-size:"small";
    color: rgb(13, 76, 205);
    cursor: pointer;
  }
  .link-hover {
    cursor: pointer;
    color: blue; padding-right: 0px;
    padding-top: -80px;
    font-size: 13px;
  }
 
 
}
@media screen and (max-width: 480px) {
 
  .cancel-style{
    bottom:5px;
    padding-left:152px  !important ;
    margin-top: -50px;
    font-size:"small";
    color: rgb(13, 76, 205);
    cursor: pointer;
  }
  .link-hover {
    cursor: pointer;
    color: blue; padding-right: 0px;
    padding-top: -80px;
    font-size: 13px;
  }
 
 
} */
.alternate-input-class {
  width: 100%;
  font-weight: normal;
  border-color: var(--neutral-300);
  border-radius: 10px;
  border-width: 1.5px;
  z-index: 1;
  padding: 0.55rem;
  border: 1px solid var(--neutral-300);
  border-radius: 0.5rem;
  background-color: var(--neutral-100);
  outline: none;
  font-weight: 500;
  width: 90% !important;
  height: 10px;
}
.alternate-input-class:focus {
  border-color: var(--primary-400);
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}
 
.add-button {
  color:blue;
  margin-top: 25px;
  margin-left:-10px;
  font-size: 2px;
 }
 
.cancel-style {
  color: red;
  margin-right:11px;
}
 

 
.error-message {
  color: red;
  font-size: 12px;
}
 
.input-div-class {
  margin-bottom: 14px; /* Add space between rows */
}
 
.container-section-details-form {
  display: flex;
  align-items: center;
}
 
.button-styles {
  display: flex;
  justify-content: flex-end;
  margin-top: -20px;
}
.alter-container{
  margin-left: -35px;
  margin-top: 10px;
  display: flex;
  align-items: center;
}
.grid-item {
  margin-left: -69px;
  margin-top: 8px;
}

.cancel-style {
  cursor: pointer;
  margin-top: 25px;
}

.add-button {
  cursor: pointer;
}
.placeholder {
  width: 9px;
}
@media (max-width: 768px) {
  .grid-item {
    margin-left: -60px;
    margin-top: 5px;
  }
  .cancel-style {
    margin-top: 15px;
    padding-left: 10px;
  }
  .add-button {
    padding-left: 8px;
    margin-right: -50px;
  }
.placeholder{
  width: 1px;
  margin-right: 1px;
}
}
@media (max-width: 1024px) {
  .grid-item {
    margin-left: -60px;
    margin-top: 5px;
  }
  .cancel-style {
    margin-top: 15px;
    padding-left: 10px;
  }
  .add-button {
    padding-left: 8px;
    margin-right: -30px;
  }
.placeholder{
  width: 1px;
  margin-right: -13px;
}
}


@media (max-width: 425px) {
  .grid-item {
    margin-left: -40px;
    margin-top: 8px;
  }
  .cancel-style {
    margin-top: 15px;
    padding-left: 2px;
  }
  .add-button {
    padding-left: 8px;
    margin-right: -30px;
  }
}

@media (max-width: 375px) {
  .grid-item {
    margin-left: -30px;
    margin-top: 8px;
  }
  .cancel-style {
    margin-top: 8px;
  }
  .add-button {
    padding-left: 8px;
    margin-right: -30px;
  }
}

@media (max-width: 320px) {
  .grid-item {
    margin-left: -20px;
    margin-top: 8px;
  }
  .cancel-style {
    margin-top: 8px;
  }
  .add-button {
    padding-left: 8px;
    margin-right: -48px;
  }
  .placeholder{
    width: 1px;
    margin-right: -28px;
  }
}
