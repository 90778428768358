*{
    font-family: "Montserrat" !important;
}
.conatiner-section{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 0px !important;
}
/* .company-name-url-container{
    margin-bottom: 8px !important;
} */
.input-company-div{
    padding-top: 0px !important;
    /* height: 40px; */
    /* padding: 8px; */
    width: 25%;
}
@media (max-width:900px){
    .input-company-div{
        padding-bottom: 10px;
    }
}
.contact-input-div{
    padding-top: 0px !important;
    /* margin-bottom: 16px !important; */
}
.right-css{
    margin-right: -10px;
}
.access-url-div{
    display: flex;
    flex-direction: row !important;
    align-items: center;
    border: 1px solid var(
        --neutral-300
        );
    border-radius: 9px;
    padding-top: -1px !important;
    height: 35px;
    margin-top: -7px !important;
}
@media (max-width:900px){
    .access-url-div{
        width: 100%;
    }
}
.left-access-url{
    text-align: center;
    border-right: 1px solid #d6d9e6;
    padding: 0px;
}
.middle-access-url{
    text-align: center;
    padding: 0px;
    height: 40px;
    height: 29px;
}
.right-access-url{
    text-align: center;
    border-left: 1px solid #d6d9e6;
    padding: 0px;
}
.access-url-container{
    display: flex;
    align-items: center;
    padding-top: 0px !important;
    height: 40px;
}
@media (max-width:900px){
    .access-url-container{
        height: auto;
    }
}
@media (max-width:426px){
    .access-url-container{
        display: flex;
        flex-direction: column !important;
        gap: 7px;
    }
}

.check-button{
    text-align: end;
    font-size: 10px !important;
    padding: 0% !important;
}
.others-container-section{
    display: flex !important;
    align-items: center;
    justify-content: center;
}
.messagePopUp{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.submit-btn-class{
    margin-top: 5px;
    display: flex;
    justify-content: center;
}
.organization-title{
    padding-top: 10px !important;
    font-family: "Montserrat" !important;  
}
.tickIcon{
    height: 80px;
}
.others-form-container{
    height: 290px;
    overflow-y: auto;
}
.organization-details-section{
    height: 366px;
    overflow-y: overlay;
}
.input-domainurl-div{
    padding-top: 0px !important;
    width: 25%;
}

.domainURL-title{
    padding-top: 10px !important;
    display: flex;
    flex-direction: row !important;
}
.infoIcon{
    margin-top: 14px;
    margin-left: 5px;
    font-size: 15px !important;
    font-family: "Montserrat" !important;  
}

.customInput {
    position: absolute;
    /* z-index: 2; */
  margin-top: 74px ;
 width: 300px!important;
  display: flex !important;
  left: 82px;
 
  height: auto;
  min-width: auto;
 
  background-color: grey;
box-shadow: 0 0 5px rgba(0.1, 0.1, 0.1, 0.1);
/* overflow: hidden; */
 
}
.customInput .MuiInputBase-input {
    font-size: 10px !important;
    color:white;
}
.guide-style{
    margin-left:12px;
    cursor: pointer;
    color: blue;
    text-decoration: underline;
    padding-top: 30px !important;
    font-family: "Montserrat" !important;  
}
.step-css{
    font-Weight:bold;
    margin-top: 50px;
    font-family: "Montserrat" !important;  
}
.steps{
    margin-bottom: 1px;
}
.modal-content{
   
margin-bottom: 30px;
justify-content: flex-start;
}
.organization-details-section{
   height: 355px;
   overflow-y: auto;
}
.input-domainurl-div{
    padding-top: 0px !important;
    width: 25%;
}
.content-modal-form{
    width:auto;
    height:auto;
   
    /* overflow-y: scroll; */
 
}
.modal-paragraph{
    /* text-transform: capitalize; */
    font-weight: 500;
    font-size: 15px;
    color: var(--primary-800);
    line-height: 23px;
    margin-bottom: -12px;
    font-family: "Montserrat" !important;  
}
.copy-text{
    color: blue;
}

    