*{
    font-family: "Montserrat" !important;
}
.input-label{
    padding: 6px;
    border-radius: 0.5rem;
    border: 2px solid var(--neutral-300);
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    box-sizing: border-box;
    width: -webkit-fill-available;
    font-weight: 600;  
    font-family: "Montserrat" !important;    
}
.active-input-label{
    padding: 6px;
    border-radius: 0.5rem;
    /* border: 2px solid hsl(213.41deg 95.65% 18.04%); */
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    font-weight: 700;
    box-sizing: border-box;
    width: -webkit-fill-available;
}

.input-label:hover, .text-input :focus {
    border: 2px solid var(--primary-400);
}

.text-input{
    font-weight: normal;
    border-color: var(--neutral-300);
    border-radius: 10px;
    border-width: 1.5px;
    z-index: 1;
    padding: 0.4rem;
    border: 1px solid var(--neutral-300);
    border-radius: 0.5rem;
    background-color: var(--neutral-100);
    outline: none;
    font-weight: 500;
    width: 90%;
    font-family: "Montserrat" !important;  
}
.email-text-input{
    font-weight: normal;
    border-color: var(--neutral-300);
    border-radius: 10px;
    border-width: 1.5px;
    z-index: 1;
    padding: 0.4rem;
    border: 1px solid var(--neutral-300);
    border-radius: 0.5rem;
    background-color: var(--neutral-100);
    outline: none;
    font-weight: 500;
    width: 195.5%;
    font-family: "Montserrat" !important;
}
.textarea-input{
    font-weight: normal;
    border-color: var(--neutral-300);
    border-radius: 10px;
    border-width: 1.5px;
    z-index: 1;
    padding: 0.4rem;
    border: 1px solid var(--neutral-300);
    border-radius: 0.5rem;
    background-color: var(--neutral-100);
    outline: none;
    font-weight: 500;
    width: 90% !important;
    height: 15px;
    /* font-family: "Montserrat", sans-serif !important; */
    font-family: "Montserrat" !important;

}
.organization-text-input{
    margin-top: -2px;
    height: 18px;
    border: none;
    border-radius: 0px;
    padding: 7px;
    width: 93%;
    background-color: var(--neutral-100);
    font-weight: 400;
    font-weight: 500;
    outline: none;
    z-index: 1;
    font-family: "Montserrat" !important;  
}
.accountNumber-type-input{
    font-weight: normal;
    border-color: var(--neutral-300);
    border-radius: 10px;
    border-width: 1.5px;
    z-index: 1;
    padding: 0.4rem;
    border: 1px solid var(--neutral-300);
    border-radius: 0.5rem;
    background-color: var(--neutral-100);
    outline: none;
    font-weight: 500;
    width: 87%;
}

.select-input{
    font-weight: normal;
    border-color: var(--neutral-300);
    border-radius: 10px;
    border-width: 1.5px;
    z-index: 1;
    padding: 0.4rem;
    border: 1px solid var(--neutral-300);
    border-radius: 0.5rem;
    background-color: var(--neutral-100);
    outline: none;
    font-weight: 500;
    width: 95%;
}

.special-text-input{
    font-weight: normal;
    border-color: var(--neutral-300);
    border-radius: 10px;
    border-width: 1.5px;
    z-index: 0;
    padding: 0.4rem;
    border: 1px solid var(--neutral-300);
    border-radius: 0.5rem;
    background-color: var(--neutral-100);
    outline: none;
    font-weight: 500;
    width: 96%;
    font-family: "Montserrat" !important;
}

@media (max-width:426px){
    .special-text-input{
        width: 94%;
    }
}

.select-input:hover, .select-input:focus {
    border-color: var(--primary-400);
    outline: none;
}
.accountNumber-type-input:hover,.accountNumber-type-input:focus{
    border-color: var(--primary-400);
    outline: none;
}
.text-input:hover, .text-input:focus {
    border-color: var(--primary-400);
    outline: none;
}
.textarea-input:hover, .textarea-input:focus {
    border-color: var(--primary-400);
    outline: none;
}
.text-input-option{
    font-weight: 500;
    font-size: 13px;
    color: var(--primary-800);
    line-height: 0.5em;
}

.special-text-input:hover, .special-text-input:focus {
    border-color: var(--primary-400);
    outline: none;
}

.text-icon{
    color: #0d4d72;
    z-index: 9;
    cursor: pointer;
    /* position: absolute; */
    margin-left: -30px;
}

.password-input{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: "Montserrat" !important;
}

.checkbox-input{
    /* padding: 2.5px; */
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border: 1px solid #d6d9e6;
    border-radius: 7px;
    justify-content: center;
    align-items: center;
    font-family: "Montserrat" !important;
}
.queue-label{
    font-size: 11px;
    font-weight: 600;
    line-height: normal;
    font-family: "Montserrat" !important;  
}
.number-select-input{
    width: 38%;
    font-weight: normal;
    border-color: var(--neutral-300);
    border-radius: 10px;
    border-width: 1.5px;
    z-index: 1;
    padding: 0.3rem;
    border: 1px solid var(--neutral-300);
    border-radius: 0.5rem;
    background-color: var(--neutral-100);
    outline: none;
    font-weight: 500;
    font-family: "Montserrat" !important;  
}
.number-input{
    font-weight: normal;
    border-color: var(--neutral-300);
    border-radius: 10px;
    border-width: 1.5px;
    z-index: 1;
    padding: 0.4rem;
    border: 1px solid var(--neutral-300);
    border-radius: 0.5rem;
    background-color: var(--neutral-100);
    outline: none;
    font-weight: 500;
    width: 78%;
    font-family: "Montserrat" !important;
}

.phoneNumber-input{
    font-weight: normal;
    border-color: var(--neutral-300);
    border-radius: 10px;
    border-width: 1.5px;
    z-index: 1;
    padding: 0.4rem;
    border: 1px solid var(--neutral-300);
    border-radius: 0.5rem;
    background-color: var(--neutral-100);
    outline: none;
    font-weight: 500;
    width: 7%;
}

.number-select-input:hover, .number-select-input:focus {
    border-color: var(--primary-400);
    outline: none;
}

.number-input:hover, .number-input:focus {
    border-color: var(--primary-400);
    outline: none;
}

.valid-div{
    padding-top: 0px !important;
  }
 
  .valid-text{
    font-size: 10px !important;
    color: green;
    font-family: "Montserrat" !important;  
  }

  .empty-text{
    opacity: 0;
    font-size: 1px !important;
  }
  

  @media (max-width:900px){
    .empty-text{
        display: none;
    }
  }