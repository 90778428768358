*{
  font-family: "Montserrat" !important;
}
.faq-bar {
  position: fixed;
  top: 0;
  right: 0;
  width: 50px; 
  height: 100vh; 
  background-color:#f0f0f0; 
  display: flex;
  flex-direction: column; 
  align-items: center;
  justify-content: flex-start; 
  z-index: 1000; 
}
@media (max-width:900px){
  .faq-bar{
    height: 37px;
    border-radius: 15px;
    box-shadow: -8px -4px 17px 4px #1976d240;
  }
}
.faq-button {
  width: 50px; 
  height: 50px; 
  border: none;
  border-top-left-radius: 10px; 
  border-bottom-left-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.faq-logo {
  width: 100%;
  height: auto;
}
.faq-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); 
  z-index: 1000; 
  display: flex;
  justify-content: center;
  align-items: center;
}
.faq-modal {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}