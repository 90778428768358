*{
  font-family: "Montserrat" !important;
}
.button-container {
    margin-top: 20px;
  }
  .custom-button {
    padding: 15px !important;
    border-radius: 15px !important;
    background-color: hsl(0deg 0% 94.12% / 40%) !important;
  }
  @media (max-width:900px){
    .custom-button{
      height: 20vh;
    }
  }
  .card-text{
    text-transform: none;
  }
  .add-agent-sub-div{
    margin-top: 6% !important;
  }
  .download-template-link{
    cursor: pointer;
    text-decoration: underline;
    color: #1976d2;
  }
 .file-uploader {
    border: 2px dashed #ccc;
    border-radius: 1px;
    padding: 50px;
    text-align: center;
    cursor: pointer;
    height: 115px;
    font-family: "Montserrat" !important;  
    margin-top: 15px; 
  }
  .file-uploader.dragging {
    border-color: #007bff;
  }
  .file-input {
    display: none;
    font-family: "Montserrat" !important;  
  }
  .file-label {
    display: block;
    font-size: 16px;
    font-family: "Montserrat" !important;  
  } 
  .file-list {
    list-style: none;
    padding: 0;
    font-family: "Montserrat" !important;  
  }
  .file-list li {
    margin-top: 5px;
  }
  .file-list li {
    
    padding: -15px;
    border-radius: 3px;
  }
  .browse-button {
    color: blue; 
    cursor: pointer; 
  }
  .browse-button:hover {
    text-decoration: underline;
  }
  .file-label-container {
    display: flex;
    justify-content: space-between;
    font-family: "Montserrat" !important;  
  }
  .cancel-button {
    background-color: #1976d2; 
    color: white; 
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    margin-top: -3px; 
    margin-left: 10px;
    cursor: pointer;
    font-family: "Montserrat" !important;  
  }
  .loading-csv-button{
    margin-left: 10px;
  }
  .disable-button{
    background-color: #f0f3f7; 
    color: rgb(14, 13, 13); 
    cursor: not-allowed;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    margin-top: 10px; 
    margin-left: 10px;
    font-family: "Montserrat" !important;  
  }
  #drop_file_zone {
    border: 2px dashed #ccc;
    padding: 20px;
    text-align: center;
    cursor: pointer;
  }
  #drag_upload_file input[type="button"] {
    background-color: #1976d2;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 5px;
    cursor: pointer;
  }
  #drag_upload_file p {
    margin: 5px 0;
  }
  #selectfile {
    display: none; 
  }
  .password-text{
    cursor: pointer;
    border: 1px solid #02299f47;
    padding: 3px;
    border-radius: 6px;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 2px;
    font-family: "Montserrat" !important;  
  }
  
  .container-section-details-form{
    display: flex !important;
    justify-content: center;
  }
 .bulk-user-success{
  color: green;
 }
 .copy-icon{
  font-size: 13px !important;
  font-family: "Montserrat" !important;  
}
 .default-password-container{
  display: flex;
  align-items: center;
 }
 .csv_h5{
  margin-top: 0px !important;
  margin-bottom: 5px;
  font-family: "Montserrat" !important;  
 }