*{
    font-family: "Montserrat" !important;
}
.form-modal{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "Montserrat" !important;  
}
.close-icon{
    float: right;
    color: #062639;
    font-weight: 800;
    cursor: pointer;
    font-family: "Montserrat" !important;  
}

.close-icon-div{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    margin-bottom: 10px;
}

.modal-container{
    background-color: #efefef !important;
    width: 50%;
}

@media (max-width:900px){
    .form-modal{
        overflow-y: auto;
    }
    .form-modal-component{
        height: 75vh;
        overflow-y: auto;
    }
    .short-modal{
        display: contents;
    }
}