*{
  font-family: "Montserrat" !important;
}
.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 21px;
  aspect-ratio: 2;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 17px;
    width: 17px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    margin-left: 1px;
  }
  
  input:checked + .slider {
    background-color: hsl(213.41deg 95.65% 18.04%);
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px hsl(213.41deg 95.65% 18.04%);
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

  .toggle-input{
    display: flex;
    gap: 10px;
    align-items: center;
    margin-top: -2px;
  }