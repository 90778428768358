*{
  font-family: "Montserrat" !important;
}
body,
html {
  margin: 0;
  padding: 0;
}
.footer {
    position: relative;
    width: 100vw; 
    background-color: #f0f0f0;
    padding: 10px 0; 
    text-align: center;
    margin-top: -30px;
    font-family: "Montserrat" !important;  
  }
  @media (max-width:900px){
    .footer{
      margin-top: -50px;
    }
  }
  .footer-copyright {
    position: absolute;
    left: 20px; 
    bottom: 20px; 
    font-size: 12px;
    white-space: nowrap;
  }
  @media (max-width: 900px){
    .footer-copyright{
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }
  @media (max-width: 426px){
    .footer-copyright{
      margin-top: 0px;
      margin-bottom: 0px;
      position: initial;
    }
  }
  .footer-content {
    margin-top: 35px;
  }
  .footer-menu {
    list-style-type: none;
    font-weight: 300px;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 50px; 
    cursor:pointer;
    bottom: 10px; 
  }
  @media (max-width:900px){
    .footer-menu{
      right: 0px;
      bottom: 15px;
    }
  }
  @media (max-width:426px){
    .footer-menu{
      display: grid;
      grid-template-columns: 2fr 2fr;
      list-style-type: none;
      bottom: 4px !important;
      right: 65px;
    }
  }
  .footer-menu li {
    display: inline;
    margin-right: 20px; 
  }
  .footer-link {
    text-decoration: none;
    color: black;
    font-size: 12px;
    white-space: nowrap;
  }
  .footer-link:hover {
    color: #666;
  }
  .button-link{
    background:none;
    border:none;
    color:black;
    font-size:12px;
    cursor:pointer;
    padding-top:20px;
  
   }