*{
  font-family: "Montserrat" !important;
}
.phraseContainer {
  display: inline-flex;
  align-items: center;
  transform: translateY(3px);
}
.phrase {
  box-Sizing: "content-box";
  background-color: rgb(209, 224, 255);
  color: #333;
  padding: 5px 10px;
  border-radius: 5px;
  margin-right: 5px; 
  margin-top: 3px;
  display: flex;
  align-items: center;
  font-size: small;
}
.button{
  bottom:5px;
  margin-left:5px ;
  margin-top: 1px;
  font-size:"small";
  color: rgb(13, 76, 205);
  cursor: pointer;
}
 .save-button-class{
  display: flex;
  justify-content: center;
 }
 .intent-container{
  display: flex;
  justify-content: center;
  align-items: center;
 }
 .intent-form-container{
  height: 270px;
  overflow-y: auto;
 }