.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    width: 400px;
    max-width: 80%;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    text-align: center;
  }
  
  .modal-content p {
    margin-bottom: 20px;
    font-size: 12px;
  }
  
  .modal-content button {
    padding: 10px 20px;
    margin: 0 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 13px;
  }
  
  .modal-content button:hover {
    background-color: #007bff;
    color: white;
  }

  .modal-content button:nth-child(2) {
    background-color: #007bff;
    color: white;
  }
  
  .modal-content button:nth-child(2):hover {
    background-color: #0056b3;
    
  }
  