*{
    font-family: "Montserrat" !important;
}
.global-button{
    background-color: rgb(13, 77, 114);
    color: white;
    border-radius: 8px;
    padding: 10px 16px;
    cursor: pointer;
    font-size: 14px;
    border: 1px solid rgb(13, 77, 114);
}
.back-button{
    background-color: aliceblue;
    color: black;
    border-radius: 8px;
    padding: 10px 16px;
    cursor: pointer;
    font-size: 14px;
    border: 1px solid rgb(13, 77, 114);
}
.check-available-button{
    background-color: rgb(13, 77, 114);
    color: white;
    border-radius: 8px;
    padding: 7px 14px;
    cursor: pointer;
    font-size: 12px;
    border: 1px solid rgb(13, 77, 114);
    margin-top: -7px;
}
.disable-check-available-button{
    background-color: rgb(240 240 240);
    color: #0d4d72;
    border-radius: 8px;
    padding: 7px 14px;
    font-size: 12.5px;
    border: none;
    cursor: not-allowed;
    margin-top: -7px;
}
.disabled-next-button{
    background-color: rgb(240 240 240);
    color: #0d4d72;
    border-radius: 8px;
    padding: 10px 16px;
    font-size: 14px;
    border: none;
    cursor: not-allowed;
}
.skip-button{
    background-color: white;
    color: rgb(13, 77, 114);
    padding: 6px 3px;
    cursor: pointer;
    font-size: 14px;
    border: 1px solid rgb(13, 77, 114);
    display: flex;
    justify-content: center;
    flex-direction: row;
    /* align-items: left; */
    border-radius: 8px;
    margin-left: -264px;
  }